import toNumber from 'lodash/toNumber';
import {
    ADD_TO_CART,
    ASK_USER_CURRENT_LOCATION,
    CHECKOUT_SIGN_IN,
    CONTINUE_PROCESSING_ACCESS_TOKEN,
    CONTINUE_PROCESSING_APP_FAV_STORE,
    CONTINUE_PROCESSING_APP_GIFT_CARDS,
    CONTINUE_PROCESSING_REFRESH_TOKEN,
    CONTINUE_PROCESSING_USER_CURRENT_LOCATION,
    CONTINUE_PROCESSING_APP_TAB_CHANGE,
    CREATE_ACCOUNT,
    CREATE_ACCOUNT_COMPLETED,
    FAVORITES_UPDATED,
    GET_ACCESS_TOKEN,
    GET_APP_FAV_STORE,
    GET_APP_GIFT_CARDS,
    GET_BLACK_BOX_ID,
    GET_REFRESH_TOKEN,
    GET_USER_EMAIL_ID,
    GET_USER_EMAIL_ID_REQUEST,
    ORDER_PLACED,
    PATCH_HEADER,
    SAVE_NATIVE_FAVORITE_STORE,
    GET_HANDLE_BACK_PRESS,
    GET_APP_TAB_CHANGE,
    SIGN_IN,
    UPDATE_CART_COUNT,
    WEB_APP_ACCOUNT_LOCKED,
    APP_UNDO_ACTION,
    WEB_GET_APP_GC_BALANCE,
    CONTINUE_PROCESSING_APP_GC_BALANCE_REQUEST,
    WEB_GET_REWARD_PROFILE,
    WEB_OPEN_QUICK_SCREEN,
    WEB_GET_REWARD_PROFILE_REQUEST,
    WEB_OPEN_QUICK_SCREEN_REQUEST,
    WEB_SYPI_WIDGET_REQUEST,
} from '../actionTypes/NativeAppActionTypes';

export const getAccessToken = (doCreateAccount = false) => ({
    type: GET_ACCESS_TOKEN,
    payload: {
        listenerType: CONTINUE_PROCESSING_ACCESS_TOKEN,
        data: {
            doCreateAccount,
        },
    },
});

export const saveNativeFavoriteStore = (storeData = '') => ({
    type: SAVE_NATIVE_FAVORITE_STORE,
    storeData,
});

export const getHandleBackPress = (isHandleBackPress = true) => ({
    type: GET_HANDLE_BACK_PRESS,
    isHandleBackPress,
});

export const getAppFavStore = (listenerType = CONTINUE_PROCESSING_APP_FAV_STORE) => ({
    type: GET_APP_FAV_STORE,
    payload: {
        listenerType,
    },
});

export const getAppTabChange = (listenerType = CONTINUE_PROCESSING_APP_TAB_CHANGE) => ({
    type: GET_APP_TAB_CHANGE,
    payload: {
        listenerType,
    },
});

export const askUserCurrentLocation = (
    listenerType = CONTINUE_PROCESSING_USER_CURRENT_LOCATION
) => ({
    type: ASK_USER_CURRENT_LOCATION,
    payload: {
        listenerType,
    },
});

export const continueProcessingAppFavStore = () => ({
    type: CONTINUE_PROCESSING_APP_FAV_STORE,
});

export const continueProcessingAppTabChange = () => ({
    type: CONTINUE_PROCESSING_APP_TAB_CHANGE,
});

export const continueProcessingUserCurrentLocation = () => ({
    type: CONTINUE_PROCESSING_USER_CURRENT_LOCATION,
});

export const getRefreshToken = () => ({
    type: GET_REFRESH_TOKEN,
    payload: {
        listenerType: CONTINUE_PROCESSING_REFRESH_TOKEN,
    },
});

export const continueProcessingAccessToken = () => ({
    type: CONTINUE_PROCESSING_ACCESS_TOKEN,
});

export const continueProcessingRefreshToken = () => ({
    type: CONTINUE_PROCESSING_REFRESH_TOKEN,
});

export const addToCart = (cartItems = []) => ({
    type: ADD_TO_CART,
    payload: {
        data: {
            cartItems,
        },
    },
});

export const updateCartCount = (cartCount, cartItems = []) => ({
    type: UPDATE_CART_COUNT,
    payload: {
        data: {
            cartCount: toNumber(cartCount),
            cartItems,
        },
    },
});

export const orderPlaced = (data) => ({
    type: ORDER_PLACED,
    payload: {
        data,
    },
});

export const appSignIn = (listenerType) => ({
    type: SIGN_IN,
    payload: {
        listenerType,
    },
});

export const checkoutSignIn = (listenerType) => ({
    type: CHECKOUT_SIGN_IN,
    payload: {
        listenerType,
    },
});

export const getUserEmailFromApp = () => ({
    type: GET_USER_EMAIL_ID,
    payload: {
        listenerType: GET_USER_EMAIL_ID_REQUEST,
    },
});

export const getBlackBoxId = () => ({
    type: GET_BLACK_BOX_ID,
});

export const createAccount = (data, listenerType = CREATE_ACCOUNT_COMPLETED) => ({
    type: CREATE_ACCOUNT,
    payload: {
        listenerType,
        data,
    },
});

export const patchHeader = (
    data = {
        backButton: true,
    }
) => ({
    type: PATCH_HEADER,
    payload: {
        data,
    },
});

export const favoritesUpdated = () => ({
    type: FAVORITES_UPDATED,
    payload: {
        data: window.jcpWebApp.getGuestUserFavList(),
    },
});

export const getNativeGiftCards = (data = []) => ({
    type: GET_APP_GIFT_CARDS,
    payload: {
        listenerType: CONTINUE_PROCESSING_APP_GIFT_CARDS,
        data,
    },
});
export const postAccountLockInfo = (data) => ({
    type: WEB_APP_ACCOUNT_LOCKED,
    payload: {
        data,
    },
});

export const appUndoAction = () => ({
    type: APP_UNDO_ACTION,
});

export const getAppGCBalance = () => ({
    type: WEB_GET_APP_GC_BALANCE,
    payload: {
        listenerType: CONTINUE_PROCESSING_APP_GC_BALANCE_REQUEST,
    },
});

export const getRewardId = () => ({
    type: WEB_GET_REWARD_PROFILE,
    payload: {
        listenerType: WEB_GET_REWARD_PROFILE_REQUEST,
    },
});

export const openQuickScreen = (data) => ({
    type: WEB_OPEN_QUICK_SCREEN,
    payload: {
        listenerType: WEB_OPEN_QUICK_SCREEN_REQUEST,
        data,
    },
});
export const getWebSYPIWidgetRequest = (data) => ({
    type: WEB_SYPI_WIDGET_REQUEST,
    payload: {
        data,
    },
});
