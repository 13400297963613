import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import get from 'lodash/get';
import { getStaticContent, getPageAggregator } from 'yoda-interfaces/lib/IrisPage/IrisPage';
import LocalStorage from 'yoda-core-components/lib/helpers/LocalStorage/LocalStorage';
import { dangerouslySetServerResponseHeaders } from '../actions/DangerouslySetServerResponseHeadersAction';
import * as IrisActionTypes from '../actionTypes/IrisActionTypes';
import analyticsActions from '../actions/AnalyticsAction';
import { selectFeatureFlags, isPreview } from '../selectors/ContextSelector';
import trimIrisData from '../iris/irisDataReduction/trimIrisData';

export function* IrisPageCallSaga(pageParams) {
    try {
        const featureFlags = yield select(selectFeatureFlags);
        const enableFullIrisDataContent = get(featureFlags, 'enableFullIrisDataContent', false);
        if (pageParams.payload.disableIrisCall) {
            const irisDatas = yield call(
                getStaticContent,
                pageParams.payload.irisFallbackPreferences,
                pageParams.payload.postProcessor
            );
            const irisDataResponse = get(irisDatas, 'data', {});
            const irisData = !enableFullIrisDataContent
                ? trimIrisData(irisDataResponse)
                : irisDataResponse;
            irisData.cacheControl = irisDatas.cacheControl;
            yield all([
                put({ type: IrisActionTypes.IRIS_PAGE_LOAD_SUCCESS, irisData }),
                put({
                    type: IrisActionTypes.IRIS_PAGE_SET_CACHE,
                    cacheControl: irisData.cacheControl,
                }),
            ]);
        } else {
            const irisDatas = yield call(
                getPageAggregator,
                pageParams.payload,
                pageParams.payload.postProcessor,
                pageParams.payload.irisFallbackEnabled,
                pageParams.payload.irisFallbackPreferences
            );
            const statusCode = irisDatas.status; // getting the status code
            const { showErrorPage, isFallingBackAfterCMFailed } = pageParams.payload;
            // Checking if statusCode is in range 199 - 300 then load the page
            if (statusCode > 199 && statusCode < 300) {
                const irisDataResponse = get(irisDatas, 'data', {});
                const irisData = !enableFullIrisDataContent
                    ? trimIrisData(irisDataResponse)
                    : irisDataResponse;
                irisData.cacheControl = irisDatas.cacheControl;
                if (
                    __SERVER__ &&
                    irisDatas.responseHeadersToProxy &&
                    irisDatas.responseHeadersToProxy.length
                ) {
                    yield put(
                        dangerouslySetServerResponseHeaders(irisDatas.responseHeadersToProxy)
                    );
                }
                yield all([
                    put({ type: IrisActionTypes.IRIS_PAGE_LOAD_SUCCESS, irisData }),
                    put({
                        type: IrisActionTypes.IRIS_PAGE_SET_CACHE,
                        cacheControl: irisData.cacheControl,
                    }),
                ]);
            } else {
                const isMktFallbackRedirectEnabled = get(
                    pageParams,
                    'payload.irisFallbackPreferences.isMktFallbackRedirectEnabled',
                    false
                );
                const url =
                    isMktFallbackRedirectEnabled &&
                    get(pageParams, 'payload.irisFallbackPreferences.marketingFallbackUrl', false);
                const redirectAction = get(pageParams, 'payload.redirectAction');
                const enableCMSwitchWidget = get(featureFlags, 'enableCMSwitchWidget', false);
                const preview = yield select(isPreview);
                if (url && redirectAction && __SERVER__) {
                    // Redirect to fallback just on the server
                    window.location.href = url;
                } else if (
                    url &&
                    !__SERVER__ &&
                    (isFallingBackAfterCMFailed || (preview && enableCMSwitchWidget))
                ) {
                    // Redirect to fallback when CoreMedia and Iris Api both failed on client side
                    LocalStorage.setData('irisEvergreenload', true, true);
                    window.location.href = url;
                } else {
                    // triggering Error Page in here
                    yield put({
                        type: IrisActionTypes.IRIS_LOAD_ERROR_PAGE,
                        statusCode,
                        showErrorPage,
                    });
                }
            }
        }
        /** If it is a client call then set this */
        /** https://jira.jcpenney.com/browse/MNPDPYODA-9947
         extra condition is for Gallery
         Since Gallery is will Load only initialy at serverSide
         rest it will call client side ex: pagination filter etc.
         */
        if (!__SERVER__ && pageParams.payload.initialLoad) {
            const errorMessage =
                'tof|the page is failed to load server but instead loaded client side';
            yield put(
                analyticsActions.triggerFormError([
                    {
                        errorDescription: errorMessage,
                    },
                ])
            );
        }
    } catch (error) {
        console.warn('IRIS:: iris api error');
        yield put({
            type: IrisActionTypes.IRIS_PAGE_LOAD_ERROR,
            error,
            showErrorPage: pageParams.payload.showErrorPage,
        });
    }
}

const watchIrisRequest = function* watchIrisRequest() {
    yield takeLatest(IrisActionTypes.IRIS_PAGE_LOAD_REQUEST, IrisPageCallSaga);
};

watchIrisRequest.sagaName = 'IrisPageCallSaga';

export default watchIrisRequest;
